import React, { useParams, useState, useEffect } from 'react';
import { faTrashCan, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import { useContext } from 'react';
import { useCart } from '../context/CartContext';
import useFacebookPixel from '../components/useFacebookPixel';

import "../css/ProductList.css"


const ProductListItem = ({ product }) => {

    // const { product, setProduct } = useState();
    const { trackAddToCart } = useFacebookPixel();
    const { cartItems, addItemToCart, removeItemFromCart, updateItemQuantity, getItemQuantity, isItemInCart } = useCart();
    // SideBar Implementation
    const [isSideBarVisible, setIsSideBarVisible] = useState(false);

    const cartItem = cartItems.find(item => item.id === product.id);
    const initialQuantity = cartItem ? cartItem.quantity : 1;
    const [quantity, setQuantity] = useState(initialQuantity);
    const unitOfSale = product.meta_data?.find(meta => meta.key === "unit_of_sale").value;

    // Issue: Once you have one item in cart added you dont have the option to remove it form the ProductListItem

    const handleQuantityChange = (event) => {
        setQuantity(Number(event.target.value));
        updateItemQuantity(product, Number(event.target.value));
        if (!isSideBarVisible) {
            toggleSideBar()
        }
    };

    const handleAddToCart = (product) => {
        addItemToCart(product);
        trackAddToCart(product.id, product.price);
        if (!isSideBarVisible) {
            toggleSideBar()
        }
    };

    useEffect(() => {
        setQuantity(getItemQuantity(product.id))
    }, [cartItems]);

    useEffect(() => {
    }, [quantity]);

    // There is a better way to implement this. Sidebar should not be created as many times as the product items. 
    const toggleSideBar = () => {
        setIsSideBarVisible(!isSideBarVisible);
    };

    return (
        <div className="products-container-list">
            <div className="product-image-wrapper">
                <Link to={`/product/${product.id}`} className={product.stock_status === "outofstock" ? "product-image out-of-stock" : "product-image"} style={{ backgroundImage: `url(${product ? product.images[0]?.src : ""})` }}></Link>
                {product.on_sale && <span className="sale-label"></span>}
                {product.tags.some(tag => tag.name === "Premium") && <span className="premium-label"></span>}
                {product.tags.some(tag => tag.name === "Feature") && <span className="feature-label"></span>}
            </div>
            <div className="product-info">
                <Link to={`/product/${product.id}`} className="product-list-title">{product.name}</Link>
                {/* <p>{product.description}</p> */}
                <div>
                    <span className="price">
                        ${product.price}
                        <span className="price-uom">{unitOfSale}</span>
                    </span>
                    {product.on_sale &&
                        <span className="regular-price">
                            ${product.regular_price}
                            <span className="price-uom">{unitOfSale}</span>
                        </span>
                    }
                </div>
            </div>
            <div className="product-list-purchase">
                {!isItemInCart(product.id) ? (
                    <>
                        {product.stock_status === 'outofstock' ?
                            <button className="product-list-cart out-of-stock" disabled>OUT OF STOCK</button> :
                            <button className="product-list-cart" onClick={() => handleAddToCart(product)}>ADD TO CART</button>
                        }
                    </>
                ) : (
                    <div className="product-list-incart">
                        <div className="cart-quantity">
                            <div className="cart-quantity-button" onClick={() => removeItemFromCart(product.id)}><FontAwesomeIcon icon={faMinus} /></div>
                            {/* <input type="number" id="quantity" name="quantity" value={quantity} onChange={handleQuantityChange} min="1" /> */}
                            <div>{quantity}</div>
                            <div className="cart-quantity-button" onClick={() => addItemToCart(product)}> <FontAwesomeIcon icon={faPlus} /></div>
                        </div>
                        <div className='item-in-cart'>IN CART</div>
                    </div>
                )}
            </div>
            <SideBar isVisible={isSideBarVisible} toggleSideBar={toggleSideBar} />
        </div >
    );
};

export default ProductListItem;